import React, {useState} from 'react';
import WhatsAppButton from "./share-buttons/WhatsAppButton";
import DownloadButton from "./share-buttons/DownloadButton";
import LinkButton from "./share-buttons/LinkButton";
import EmailButton from "./share-buttons/EmailButton";
import FacebookButton from "./share-buttons/FaceBookButton";
import TwitterButton from "./share-buttons/TwitterButton";
import styles from './ShareBar.module.scss';
import html2canvas from 'html2canvas'

const ShareBar = (props) => {
    if (props.cityurl.endsWith('/')) {
      props.cityurl = props.cityurl.slice(0, -1);
    }
    const mediaShareAddress = `https://${props.cityurl}/stories-shared-media`;
    let mediaShareURL = '';
    let shareText = '';
    let gameData = '';
    let videoTypes = ['VIDEO', 'ACTION REPLAY', 'LOOPCAM'];
    let azureCode = '';
    let downLoadLink = '';



    if (mediaShareAddress.endsWith('/')) {
      mediaShareAddress = mediaShareAddress.slice(0, -1);
    }
    if(props.mediaShare.mediaType == 'PHOTO') {
        downLoadLink = props.mediaShare.downloadURI;
        mediaShareURL = `${mediaShareAddress}?u=photo/${props.mediaShare.mediaURI.substring(0, props.mediaShare.mediaURI.lastIndexOf('.'))}&t=i` ;
        shareText = 'Check%20out%20our%20photo%20from%20Flight%20Club%3A%20'
    } else if (videoTypes.indexOf(props.mediaShare.mediaType) >= 0) {
        if(props.mediaShare.videoURL){
          downLoadLink = props.mediaShare.videoURL;   
          azureCode = props.mediaShare.videoURL.replace(props.azureurl, '').replace(props.videourl, '').replace('.ism/manifest', '');
          let vidType = props.mediaShare.videoURL.includes('.mp4') ? 'vidblob' : 'vidaz';
          mediaShareURL = `${mediaShareAddress}?u=${vidType}${azureCode}&t=v&p=a`;
        }else{
          mediaShareURL = `${mediaShareAddress}?u=vidvi/${props.mediaShare.mediaURI}&t=v&p=v`;
        }
        shareText = 'Check%20out%20our%20video%20from%20Flight%20Club%3A%20'
    }else if(props.mediaShare.mediaType == "GAME"){
        mediaShareURL = `${mediaShareAddress}?u=game/${props.mediaShare.mediaURI}&t=i`;
        shareText = 'Check%20out%20our%20game%20from%20Flight%20Club%3A%20'
        gameData = props.mediaShare.gameData;
    }

    if(props.country === 'us') {
        mediaShareURL = 'https://stories.us.flightclubdarts.com/' + props.guid;
    }


    return(
        <div className={ styles.ShareBar }>
            <DownloadButton mediaShareURL={ downLoadLink } shareText={shareText} mediaType={props.mediaShare.mediaType} gameData={gameData} sectionName={props.mediaShare.sectionName}/>
            <WhatsAppButton mediaShareURL={ mediaShareURL } shareText={shareText} mediaType={props.mediaShare.mediaType} gameData={gameData} sectionName={props.mediaShare.sectionName}/>
            <LinkButton mediaShareURL={ mediaShareURL } shareText={shareText} mediaType={props.mediaShare.mediaType} gameData={gameData} sectionName={props.mediaShare.sectionName}/>
            <EmailButton mediaShareURL={ mediaShareURL } shareText={shareText} mediaType={props.mediaShare.mediaType} gameData={gameData} sectionName={props.mediaShare.sectionName}/>
            <FacebookButton mediaShareURL={ mediaShareURL } shareText={shareText} mediaType={props.mediaShare.mediaType} gameData={gameData} sectionName={props.mediaShare.sectionName}/>
            <TwitterButton mediaShareURL={ mediaShareURL } shareText={shareText} mediaType={props.mediaShare.mediaType} gameData={gameData} sectionName={props.mediaShare.sectionName}/>
        </div>
    )
};
export default ShareBar;