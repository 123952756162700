// Base container for the app.
// data Pulled from Azure, sorted as required and passed to each component
// Component are then built into the app if there is data associated.
import React, { useContext, useState } from 'react';
import { Context } from '../Store';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Header from '../components/Header';
import Players from '../components/Players';
import ActionReplays from '../components/ActionReplays';
import GroupPhotos from '../components/GroupPhotos';
import Games from '../components/Games';
import Loopcams from '../components/Loopcams';
import BannerContainer from '../components/BannerContainer';
import CopyLinkContainer from '../components/CopyLinkContainer/CopyLinkContainer';
import LoadingStory from '../components/LoadingStory';

const StoryContainer = () => {
  // Setting app context to Store.js Where the overall state is kept
  const [state, setState] = useContext(Context);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const debug = params.get('debug');

  // set up azure Var
  let StoriesAzureAPI;
  let country = window.location.href.indexOf(".us.") != -1 ?
     'us' : window.location.href.indexOf('.au') != -1 ? 
     'aus' : window.location.href.indexOf('.ie') != -1 ? 
     'ie' : 'uk';




  // Check url to select appropriate Azure connection
  if (country == 'us') {
    StoriesAzureAPI = axios.create({
      baseURL: 'https://usstoryfunctions.azurewebsites.net/api/',
      params: {
        code: `${process.env.REACT_APP_US_KEY}`,
      },
      headers: {
        'Content-type': 'application/json',
      },
    });
    StoriesAzureAPI.interceptors.request.use((config) => {
      config.params = {
        code: `${process.env.REACT_APP_US_KEY}`,
        ...config.params,
      };
      return config;
    });
  } else if (country == 'aus') {
    StoriesAzureAPI = axios.create({
      baseURL:
        'https://ausstoryfunctions-apim.azure-api.net/AusStoryFunctions/StoryData/',
      headers: {
        'Content-type': 'application/json',
        'FC-Key': `${process.env.REACT_APP_AUS_KEY}`,
      },
    });
  } else if (country == 'ie') {
    StoriesAzureAPI = axios.create({
      baseURL:
        'https://irlstoryfunctions-apim.azure-api.net/StoryFunctionsIRL/StoryData/',
      headers: {
        'Content-type': 'application/json',
        'FC-Key': `${process.env.REACT_APP_IE_KEY}`,
      },
    });
  } else {
    StoriesAzureAPI = axios.create({
      baseURL: 'https://flightclubapi.azure-api.net/uk/v2/StoryData/',
      headers: {
        'Content-type': 'application/json',
        'FC-Key': `${process.env.REACT_APP_UK_KEY}`,
      },
    });
  }

  // On load will use the string after the first slash (/) as the GUID
  // to request Stories data from the Azure API. Will work both with or without the trailing #/ in the URL
  let { guid } = useParams();

  // Set up all Venues with appropriate variables including Disploay name, city, website URL and blob url
  const venues = [
    {
      code: 'BLOOM',
      name: 'Bloomsbury',
      city: 'london',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/3DtHvR3',
    },
    {
      code: 'BIRMI',
      name: 'Birmingham',
      city: 'birmingham',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/3P7w9ov',
    },
    {
      code: 'ISLIN',
      name: 'Islington',
      city: 'london',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/4iIj82h',
    },
    {
      code: 'MANCH',
      name: 'Manchester',
      city: 'manchester',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/41IEhmM',
    },
    {
      code: 'SHORE',
      name: 'Shoreditch',
      city: 'london',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/41HR5d7',
    },
    {
      code: 'VICTO',
      name: 'Victoria',
      city: 'london',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/4iN3YZr',
    },
    {
      code: 'LEEDS',
      name: 'Leeds',
      city: 'leeds',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/3VSfw3O',
    },
    {
      code: 'BRIST',
      name: 'Bristol',
      city: 'bristol',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/49OalYv',
    },
    {
      code: 'CHELT',
      name: 'Cheltenham',
      city: 'cheltenham',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/4gQLp50',
    },
    {
      code: 'CARDI',
      name: 'Cardiff',
      city: 'cardiff',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/3DsqiYl',
    },
    {
      code: 'GLASG',
      name: 'Glasgow',
      city: 'glasgow',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/49PHPpm',
    },
    {
      code: 'EDINB',
      name: 'Edinburgh',
      city: 'edinburgh',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/4fvZzav',
    },
    {
      code: 'LIVER',
      name: 'Liverpool',
      city: 'liverpool',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/41MJqdm',
    },
    {
      code: 'OXFOR',
      name: 'Oxford',
      city: 'oxford',
      url: 'flightclubdarts.com',
      blob_url: 'https://flightclubdarts.blob.core.windows.net',
      azure_url: 'https://storiesvideos-ukso1.streaming.media.azure.net',
      video_url: 'https://storiesvideostorage.blob.core.windows.net',
      feedback_url: 'https://bit.ly/41NDn8t',
    },
    {
      code: 'BOSTO',
      name: 'Boston',
      city: 'boston',
      url: 'https://flightclubdartsusa.com/boston/seaport',
      blob_url: 'https://flightclubdartsusa.blob.core.windows.net',
      azure_url: 'https://storiesvideosus-usso.streaming.media.azure.net',
      video_url: '',
      feedback_url: 'https://g.page/r/CUeXtkyvsyNhEBM/review',
    },
    {
      code: 'CHICA',
      name: 'Chicago',
      city: 'chicago',
      url: 'flightclubdartsusa.com/chicago/wacker',
      blob_url: 'https://flightclubdartsusa.blob.core.windows.net',
      azure_url: 'https://storiesvideosus-usso.streaming.media.azure.net',
      video_url: '',
      feedback_url: 'https://g.page/r/CbJJgIqLqxJcEBM/review',
    },
    {
      code: 'HOUST',
      name: 'Houston',
      city: 'houston',
      url: 'flightclubdartsusa.com/houston/regent-square',
      blob_url: 'https://flightclubdartsusa.blob.core.windows.net',
      azure_url: 'https://storiesvideosus-usso.streaming.media.azure.net',
      video_url: '',
      feedback_url: 'https://g.page/r/CQfLfyKpa_cxEBM/review',
    },
    {
      code: 'LASVE',
      name: 'LasVegas',
      city: 'lasvegas',
      url: 'flightclubdartsusa.com/las-vegas/the-strip',
      blob_url: 'https://flightclubdartsusa.blob.core.windows.net',
      azure_url: 'https://storiesvideosus-usso.streaming.media.azure.net',
      video_url: '',
      feedback_url: 'https://g.page/r/CYYyULVGpuZ0EBM/review',
    },
    {
      code: 'ATLAN',
      name: 'Atlanta',
      city: 'atlanta',
      url: 'flightclubdartsusa.com/atlanta/atlanta',
      blob_url: 'https://flightclubdartsusa.blob.core.windows.net',
      azure_url: 'https://storiesvideosus-usso.streaming.media.azure.net',
      feedback_url: 'https://g.page/r/CYWwAV9OgmtLEBM/review',
    },
    {
      code: 'PERTH',
      name: 'Perth',
      city: 'perth',
      url: 'flightclubdarts.com.au',
      blob_url: 'https://flightclubdartsaus.blob.core.windows.net',
      azure_url: 'https://storiesvideosaus-aueas.streaming.media.azure.net',
      video_url: 'https://videostorageaus.blob.core.windows.net',
      feedback_url: '',
    },
    {
      code: 'FREMA',
      name: 'Fremantle',
      city: 'Fremantle',
      url: 'flightclubdarts.com.au',
      blob_url: 'https://flightclubdartsaus.blob.core.windows.net',
      azure_url: 'https://storiesvideosaus-aueas.streaming.media.azure.net',
      video_url: 'https://videostorageaus.blob.core.windows.net',
      feedback_url: '',
    },
    {
      code: 'SYDNE',
      name: 'Sydney',
      city: 'Sydney',
      url: 'flightclubdarts.com.au',
      
      blob_url: 'https://flightclubdartsaus.blob.core.windows.net',
      azure_url: 'https://storiesvideosaus-aueas.streaming.media.azure.net',
      video_url: 'https://videostorageaus.blob.core.windows.net',
      feedback_url: '',
    },
    {
      code: 'MELBO',
      name: 'Melbourne',
      city: 'Melbourne',
      url: 'flightclubdarts.com.au',
      blob_url: 'https://flightclubdartsaus.blob.core.windows.net',
      azure_url: 'https://storiesvideosaus-aueas.streaming.media.azure.net',
      video_url: 'https://videostorageaus.blob.core.windows.net',
      feedback_url: 'https://bit.ly/3Yt7mPF',
    },
    {
      code: 'DENVE',
      name: 'Denver',
      city: 'denver',
      url: 'flightclubdartsusa.com/denver/denver',
      blob_url: 'https://flightclubdartsusa.blob.core.windows.net',
      azure_url: 'https://storiesvideosus-usso.streaming.media.azure.net',
      feedback_url: 'https://g.page/r/CUCOqGLKmOtyEBM/review',
    },
    {
      code: 'WASHI',
      name: 'Washington',
      city: 'washington',
      url: 'flightclubdartsusa.com/washington/dc',
      blob_url: 'https://flightclubdartsusa.blob.core.windows.net',
      azure_url: 'https://storiesvideosus-usso.streaming.media.azure.net',
      feedback_url: '',
    },
    {
      code: 'STLOU',
      name: 'StLouis',
      city: 'stlouis',
      url: 'flightclubdartsusa.com/st-louis/st-louis',
      blob_url: 'https://flightclubdartsusa.blob.core.windows.net',
      azure_url: 'https://storiesvideosus-usso.streaming.media.azure.net',
      feedback_url: '',
    },
    {
      code: 'PHILA',
      name: 'Philadelphia',
      city: 'philadelphia',
      url: 'flightclubdartsusa.com/philadelphia/philadelphia',
      blob_url: 'https://flightclubdartsusa.blob.core.windows.net',
      azure_url: 'https://storiesvideosus-usso.streaming.media.azure.net',
      feedback_url: '',
    },
    {
      code: 'DUBLI',
      name: 'Dublin',
      city: 'dublin',
      url: 'flightclubdarts.com/ie',
      blob_url: 'https://flightclubdartsirl.blob.core.windows.net',
      azure_url: '',
      video_url: 'https://videostorageirl.blob.core.windows.net',
      feedback_url: '',
    },
  ];
  // function to get Venue array based on story data venue code
  const getVenueName = (venue_code) => {
    let venue = {};
    venues.forEach((item) => {
      if (venue_code.slice(0, 5).toUpperCase() == item.code) {
        venue = {
          venue_name: item.name,
          city_name: item.city,
          city_url: item.url,
          blob_url: item.blob_url,
          azure_url: item.azure_url,
          video_url: item.video_url,
          feedback_url: item.feedback_url,
        };
      }
    });
    return venue;
  };

  // Get story data from GUID
  const getStoryData = async (id) => {
    // Get story data depending on url (USA is using a slightly differnt system which requires a different call)
    let story;
    if (country === 'us') {
      story = await await StoriesAzureAPI.get('GetStoryData', {
        params: { id: id },
      }).then((response) => {
        if (debug) console.log('API request response:', response);
        return response;
      });
    } else {
      story = await await StoriesAzureAPI.get(id).then((response) => {
        if (debug) console.log('API request response:', response);
        return response;
      });
    }
    // After raw Story data has been pulled we trigger getVenueName
    let venue = getVenueName(story.data.venue_code);

    // Get all events from story and sort them into different arrays for photos, loopcams, actionreplays and games
    let events = story.data.Newsfeed.reduce(
      (data, event) => {
        if (event.type === 201) {
          // Event 201 - Photo. If Uri exists add it to array
          if (event.asset_uri !== '') {
            data.photos.push(event);
          }
        } else if (event.type === 200) {
          // Event 200 - Loopcam. If Uri exists add it to array
          if (event.asset_uri !== '') {
            data.loopcams.push(event);
          }
        } else if (event.type >= 100 && event.type <= 150) {
          // Event between 100 - 150 - Action Replay. if Uri exits add it to array
          if (event.asset_uri !== '') {
            data.actionReplays.push(event);
          }
        } else if (event.type >= 1 && event.type <= 30) {
          // Event 1- 30 Game Events.
          // CHeck for event 1(Game start) Add as new game type to game object. This only lets there be one object for each game type
          if (event.type == 1) {
            if (!(event.game in data.games)) {
              data.games[event.game] = [];
            }
          }
          // add each game event to the relevent game type object
          data.games[event.game].push(event);
        }
        return data;
      },
      {
        actionReplays: [],
        loopcams: [],
        photos: [],
        games: {},
      }
    );
    //console.log(events)

    //set up array for game events
    let gameEvents = [];
    //loop through each game type from games object
    for (let [key, game] of Object.entries(events.games)) {
      //console.log(key, game)
      // set highscor var to black at the start of each loop
      let highscore;
      // switch to do different array manipulation dependant on game type
      switch (key) {
        case 'DEMOLITION':
          // map array to be value of info if event is type 2 (High 3 dart score) otherwise set it to 0
          // use math max apply to new array to get the highest value.
          highscore = Math.max.apply(
            null,
            game.map(function (o) {
              if (o.type == 2) {
                return +o.info;
              } else {
                return 0;
              }
            })
          );
          if (highscore != 0) {
            // if high score exists get full event object and if a player exists in that object add it to the game events array
            let obj = game.find(function (o) {
              return o.info == highscore;
            });
            if (obj.players) {
              gameEvents.push(obj);
            }
          }
          break;
        case 'SHANGHAI':
          // map array to be value of info(1st in list * 2nd item in list) if event is type 9 (High 3 dart score) otherwise set it to 0
          // use math max apply to new array to get the highest value.
          highscore = Math.max.apply(
            null,
            game.map(function (o) {
              if (o.type == 9) {
                return +o.info.split(',')[0] * +o.info.split(',')[1];
              } else {
                return 0;
              }
            })
          );
          if (highscore != 0) {
            // if high score exists get full event object and if a player exists in that object add it to the game events array
            let obj = game.find(function (o) {
              return +o.info.split(',')[0] * +o.info.split(',')[1] == highscore;
            });
            if (obj.players) {
              gameEvents.push(obj);
            }
          }
          break;
        case 'QUACKSHOT':
          //Creating new array with player and player score(info). reduce will loop through each item in array and check for the score
          let duckScore = game.reduce((r, { players, info, type }) => {
            // Look for event 10 (Bulleye's)
            if (type == 10) {
              //Look for player in new socre array
              var temp = r.find((o) => o.players === players);
              //if player exists add this score to the existing socre
              if (temp) {
                temp.info += info.split(',').reduce((a, b) => +a + +b);
              } else {
                // if player doesnt exist in new array yet5 add it in
                let score = info.split(',').reduce((a, b) => +a + +b);
                if (players) {
                  r.push({ players, info: score });
                }
              }
            }
            return r;
          }, []);
          // If there are any bullseye scores find highest by loopong through the array via reduce and comparing current score to current max
          if (duckScore.length)
            highscore = duckScore.reduce((max, score) =>
              max.info > score.info ? max : score
            );
          if (highscore) {
            // if a highscore exists find player details and add to game events array
            let obj = game.find(function (o) {
              return o.players === highscore.players;
            });
            gameEvents.push(obj);
          }
          break;
        case 'KILLER':
          //Creating new array with player abd kill count. reduce will loop through each item in array and check for kill events
          let killScore = game.reduce((r, { players, type }) => {
            //Look for event type 13 (Kill event)
            if (type == 13) {
              //check new array to see if playe already exists. Iff they do increase kill count by one
              var temp = r.find((o) => o.players === players);
              if (temp) {
                temp.count++;
              } else {
                // if player doesnt exist add them to arry with count of one
                let score = 1;
                r.push({ players, count: score });
              }
            }
            return r;
          }, []);
          // If there are any Kills find highest by loopong through the array via reduce and comparing current score to current max
          if (killScore.length)
            highscore = killScore.reduce((max, score) =>
              max.count > score.count ? max : score
            );
          if (highscore) {
            // if a highscore exists find player details and add to game events array
            let obj = game.find(function (o) {
              return o.players === highscore.players;
            });
            gameEvents.push(obj);
          }
          break;
        case 'SNAKESANDLADDERS':
          //Creating new array with player and snake count. reduce will loop through each item in array and check for Snake events
          let snakeScore = game.reduce((r, { players, type }) => {
            //Look for event type 15 (Snake event)
            if (type == 15) {
              //check new array to see if playe already exists. If they do increase snake count by one
              var temp = r.find((o) => o.players === players);
              if (temp) {
                temp.count++;
              } else {
                // if player doesnt exist add them to array with count of one
                let score = 1;
                r.push({ players, count: score });
              }
            }
            return r;
          }, []);
          // If there are any snake evtns find highest by looping through the array via reduce and comparing current score to current max
          if (snakeScore.length)
            highscore = snakeScore.reduce((max, score) =>
              max.count > score.count ? max : score
            );
          if (highscore) {
            // if a highscore exists find player details and add to game events array
            let obj = game.find(function (o) {
              return o.players === highscore.players;
            });
            gameEvents.push(obj);
          }
          break;
        case 'DONKEYDERBY':
          // code block
          break;
        default:
          // default
          break;
      }
    }
    // Set state variables to story data and event data
    setState({
      ...state,
      guid: guid,
      type: story.data.type,
      reference: story.data.reference,
      country: country,
      venue: venue.venue_name,
      city: venue.city_name,
      cityurl: venue.city_url,
      bloburl: venue.blob_url,
      azureurl: venue.azure_url,
      videourl: venue.video_url,
      feedbackurl: venue.feedback_url,
      players: story.data.Players,
      teams: story.data.Teams,
      newsfeed: story.data.Newsfeed,
      photos: events.photos,
      actionReplays:
        story.data.type == '1' || story.data.type == '3'
          ? events.actionReplays
              .slice(
                events.actionReplays.length - 10 < 0
                  ? 0
                  : events.actionReplays.length - 10,
                events.actionReplays.length
              )
              .reverse()
          : events.actionReplays,
      loopcams: events.loopcams,
      games: gameEvents,
      debug: debug,
    });
    // GGoogle analytics trigger open story event
    ReactGA.event({
      category: 'Story opened',
      action: 's: ' + window.location.host + ' v: ' + venue.venue_name,
    });
  };

  // Check if state has been set. If not get data from guid and set display to "Loading"
  if (state.guid.length < 1) {
    getStoryData(guid);
    return (
      <div>
        <LoadingStory />
      </div>
    );
  }

  let setCurrentLightbox = null;
  const openLightbox = (lightboxDetails) => {
    if (lightboxDetails) {
      setCurrentLightbox(lightboxDetails);
    }
    setCurrentPreview('');
  };
  const updateLightbox = (dataFromChild) => {
    setCurrentLightbox = dataFromChild;
  };

  let hoverDelay;
  let setCurrentPreview = null;

  const handleHover = (previewDetails, type) => {
    clearTimeout(hoverDelay);
    if (type == 'mouseenter') {
      hoverDelay = setTimeout(() => setCurrentPreview(previewDetails), 250);
    }
  };

  const updatePreview = (dataFromChild) => {
    setCurrentPreview = dataFromChild;
  };

  return (
    <div>
      <Context.Provider value={state}>
        <Header />
        <Players />
        {state.actionReplays.length >= 1 && (
          <ActionReplays
            handleHover={handleHover}
            openLightbox={openLightbox}
          />
        )}
        {state.photos.length >= 1 && (
          <GroupPhotos handleHover={handleHover} openLightbox={openLightbox} />
        )}
        {state.games.length >= 1 && (
          <Games handleHover={handleHover} openLightbox={openLightbox} />
        )}
        {state.loopcams.length >= 1 && (
          <Loopcams handleHover={handleHover} openLightbox={openLightbox} />
        )}

        <BannerContainer />

        <CopyLinkContainer
          mount={updatePreview}
          callbackHandler={openLightbox}
          updateLightbox={updateLightbox}
        />
      </Context.Provider>
    </div>
  );
};

export default StoryContainer;
