import React, { useState, useRef, useEffect } from 'react';
import styles from './BannerContainer.module.scss';

const BannerContainer = (props) => {
  return (
    <div className={'section'}>
      <div className={styles.BannerContainer}>
        <a
          href={`https://flightclubdarts.com/flightclub10/game7?utm_source=banner&utm_medium=website&utm_campaign=game7&utm_content=stories-page`}
          target='_blank'
        >
          <img
            className={styles.BannerDesk}
            src='./img/fc_game7_banner_wide-v2.webp'
            alt='Flight Club Game 7'
          />
          <img
            className={styles.BannerMob}
            src='./img/fc_game7_banner_2-1-v2.webp'
            alt='Flight Club Game 7'
          />
        </a>
      </div>
    </div>
  );
};

export default BannerContainer;
