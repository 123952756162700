import React, { useContext } from 'react';
import styles from './Footer.module.scss';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactGA from "react-ga4";
import { Context } from '../Store';
import CopiedContext from '../CopiedContext';

const Footer = (props) => {
  const state = React.useContext(Context); 
  const { showCopied, setShowCopied } = useContext(CopiedContext);
  let mediaShareURL;
  if( state.country === 'us' ) {
    mediaShareURL = 'https://stories.us.flightclubdarts.com/' + state.guid;
  }else{
    mediaShareURL = 'https://' + state.cityurl + '/shared-story?g=' + state.guid;
  }

  const encodedLink = encodeURIComponent(mediaShareURL);

  let shareText = 'Check%20out%20my%20Flight%20Club%20story%3A'

  let feedbackLink


  let trackEvent = (category, action, label = '') => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  }

  return (
    <div className={styles.footer}>
      <h2>Share your story</h2>
      <div className={styles.shareLinks}>
            <div className={styles.shareItem} onClick={() => {window.open('https://api.whatsapp.com/send?text=' + shareText + encodeURI(mediaShareURL)); trackEvent("Share", "Share Story", "Whatsapp")}} >
                <div className={styles.shareImage}><img src="./img/share_icon_whatsapp.svg" alt="Whatsapp" /></div>
                {/* <div className={styles.shareText}>Whatsapp</div> */}
            </div>
            <CopyToClipboard text={mediaShareURL} onCopy={() => setShowCopied(true)}>
              <div className={styles.shareItem} onClick={() => {trackEvent("Share", "Share Story", "Copy to clipboard")}}>
                  <div className={styles.shareImage}><img src="./img/share_icon_link.svg" alt="Link" /></div>
                  {/* <div className={styles.shareText}>Link</div> */}
              </div>
            </CopyToClipboard>
            <div className={styles.shareItem} onClick={() => {
                window.location.href = 'mailto:?body=' + shareText + ' ' + encodedLink;
                trackEvent("Share", "Share Story", "Email") 
                } }>
                <div className={styles.shareImage}><img src="./img/share_icon_email.svg" alt="Email" /></div>
            </div>
            <div className={styles.shareItem} onClick={() => {window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(mediaShareURL) + '&quote=' + shareText); trackEvent("Share", "Share Story", "Facebook")} }>
                <div className={styles.shareImage}><img src="./img/share_icon_facebook.svg" alt="Facebook" /></div>
                
            </div>
            <div className={styles.shareItem} onClick={() => {window.open('http://twitter.com/intent/tweet?text=' + shareText + ' ' + encodeURI(mediaShareURL)); trackEvent("Share", "Share Story", "Twitter")}}>
                <div className={styles.shareImage}><img src="./img/share_icon_twitter.svg" alt="Twitter" /></div>
                {/* <div className={styles.shareText}>Twitter</div> */}
            </div>
      </div>
      {/* { state.feedbackurl !== '' &&
      <div className={styles.footerFeedback}>
      Enjoyed your time with us? We'd love to hear your feedback <a href={state.feedbackurl} target="_blank" >here</a>. 
      <br/>
      Enter for a chance to win a monthly prize of £250! 
      </div>
      } */}
      <div className={styles.footerLinks}>
        { state.country === 'us' ?
        <a href={`https://${state.cityurl}/`} onClick={() => trackEvent("External Link", "Book Again")} ><div className={styles.footerLinkItem}>BOOK AGAIN</div></a> : 
        <a href={`https://${state.cityurl}/book`} onClick={() => trackEvent("External Link", "Book Again")} ><div className={styles.footerLinkItem}>BOOK AGAIN</div></a> }
        { state.country !== 'us' &&
          <>
            <div><img src="./img/footer_divider.png" alt="" /></div>
            <a href={`https://${state.cityurl}/privacy-policy`} onClick={() => trackEvent("External Link", "Privacy Policy")}><div className={styles.footerLinkItem}>PRIVACY</div></a>
          </>
        }
        <div><img src="./img/footer_divider.png" alt="" /></div>
        <a href={`https://${state.cityurl}/faqs`} onClick={() => trackEvent("External Link", "FAQS")}><div className={styles.footerLinkItem}>FAQS</div></a>
      </div>
    </div>
  );

}

export default Footer;